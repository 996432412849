// import { useJsApiLoader } from '@react-google-maps/api';
import type { Dispatch, SetStateAction } from "react";
// import { useState } from 'react';
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

export function AutoLocation({
  setLocation,
  apiKey,
}: {
  apiKey: any;
  setLocation: Dispatch<
    SetStateAction<null | { value: { description: string } }>
  >;
}) {
  // const { isLoaded } = useJsApiLoader({
  //     googleMapsApiKey: apiKey,
  //     libraries:['places'],
  //     nonce:'SAUK',
  // });
  return (
    <GooglePlacesAutocomplete
      apiOptions={{
        language: "gb",
        region: "uk",
        libraries: ["places"],
        nonce: "SAUK",
      }}
      
      selectProps={{
        placeholder: "Enter location",
        onChange: setLocation,
      }}
      apiKey={apiKey}
    />
  );
}
