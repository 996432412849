import * as SliderPrimitive from "@radix-ui/react-slider";

import type { SliderProps } from "~/types";

export const Slider = ({ label, state, radius, isMobile }: SliderProps) => (
  <>
    <div className="flex justify-between mt-2 mb-3">
      <span
        className={`text-xl font-bold ${isMobile ? "dark:text-white" : ""}`}
      >
        {label}
      </span>
      <span className="text-base font-normal font-SourceSans  dark:text-white">
        {radius} miles
      </span>
    </div>
    <SliderPrimitive.Root
      className="relative flex items-center w-full h-full "
      defaultValue={[radius]}
      value={[radius]}
      max={50}
      step={5}
      onValueChange={(value) => {
        state(value[0])
      }}
    >
      <SliderPrimitive.Track className="relative flex-grow h-5 rounded-lg bg-staff-60">
        <SliderPrimitive.Range className="absolute w-full h-full rounded-lg bg-staff-200" />
      </SliderPrimitive.Track>
      <SliderPrimitive.Thumb className="block h-7 w-7 bg-white  rounded-[30px] border-2 border-staff-160 hover:cursor-pointer " />
    </SliderPrimitive.Root>
  </>
);
