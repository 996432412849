import { Fragment } from "react";

import { Dialog, Transition } from "@headlessui/react";
type Props = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  children: React.ReactNode;
  className?: string;
};

export function DialogModel({ children, isOpen, setIsOpen }: Props) {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 h-screen"
          onClose={() => setIsOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed  inset-0 overflow-y-auto overflow-x-hidden">
            <div className=" dark:bg-staff-540">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="z-50 w-screen h-screen overflow-y-auto overflow-x-hidden text-left align-middle transition-all transform bg-white dark:bg-staff-540 shadow-xl">
                  <div className="h-screen">
                    <div className="relative flex justify-end">
                      <button
                        data-testid="SAUK-mobile-search-modal-close-btn"
                        type="button"
                        onClick={() => setIsOpen(false)}
                        style={{
                          top: "25px",
                          right: "25px",
                          position: "absolute",
                        }}
                      >
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          className=" stroke-black dark:stroke-white"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 18L18 6M6 6L18 18"
                            stroke=" "
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="h-screen flex flex-col justify-between">
                      {children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
