// src/exampleSlice.js
import { createSlice } from '@reduxjs/toolkit';

const jobSlice = createSlice({
    name: 'job',
    initialState: {
        jobs: [],
        filteredJobs: [],
        prevScrollPos: 0,
        pageNumber: 1,
        totalRecords: 0
    },
    reducers: {
        setJobsToStore: (state, action) => {
            state.jobs = action.payload;
        },
        setFilteredJobsToStore: (state, action) => {
            state.filteredJobs = action.payload;
        },
        setPrevScrollPosToStore: (state, action) => {
            state.prevScrollPos = action.payload;
        },
        setPageNumberToStore: (state, action) => {
            state.pageNumber = action.payload;
        },
        setTotalRecordsToStore: (state, action) => {
            state.totalRecords = action.payload;
        }
    },
});

export const jobReducer = jobSlice.reducer;

export const { setJobsToStore, setFilteredJobsToStore, setPrevScrollPosToStore, setPageNumberToStore,setTotalRecordsToStore } = jobSlice.actions;
