import Lottie from 'react-lottie';
import searchAnimation from '../../../../public/search-lottie.json';

export const SearchLottie = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: searchAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };
    return (
        <div>
            {' '}
            <Lottie options={defaultOptions} height={40} width={50} />
        </div>
    );
};
